<template>
  <div
    class="flex justify-center items-center rounded-md text-white font-bold border-3 uppercase"
    :class="[bgClass, sizeClass, { 'border-none hover:border-solid hover:border-gray-200' : !isActive, 'border-solid border-gray-100' : isActive }]"
  >
    <img
      v-if="hasCover"
      class="rounded object-cover"
      :src="team.cover_url"
      :alt="'Icon of ' + team.name"
    >
    <span v-else>{{ team.name | firstchar }}</span>
  </div>
</template>
<script>
import colorIcons from '@components/team/TeamColorIcons.js'

export default {
  name: 'TeamIcon',

  mixins: [colorIcons],

  props: {
    team: {
      type: Object,
      required: true
    },

    isActive: {
      type: Boolean,
      default: false
    },

    size: {
      type: String,
      default: 'md'
    }
  },

  computed: {
    sizeClass () {
      if (this.size === 'lg') return 'w-16 h-16 text-2xl'
      return 'w-10 h-10 text-lg'
    }
  }
}
</script>
