
import PrevizDirectUpload from '@/libs/direct-upload.js'
import ImageResize from 'image-resize'

const DirectUpload = new PrevizDirectUpload()

export default {

  data () {
    return {
      coverForm: new SparkForm({}),

      colors: {
        gray: 'gray-icon',
        red: 'bg-red-500',
        orange: 'bg-orange-500',
        yellow: 'bg-yellow-500',
        green: 'bg-green-500',
        blue: 'bg-blue-500',
        purple: 'bg-purple-500'
      }
    }
  },

  computed: {

    hasCover () {
      return this.team.cover_url !== '' && this.team.cover_url !== undefined
    },

    bgClass () {
      return this.colors[this.team.cover_color]
    }
  },

  methods: {

    onChangeColor (color) {
      this.$store.dispatch('teams/updateColor', {
        team: this.team,
        color: color
      })
      this.$emit('updated')
    },

    onClear () {
      if (window.confirm('Are you sure you want to remove this team icon?')) {
        this.coverForm.startProcessing()
        this.$store.dispatch('teams/removeIcon', { id: this.team.id })
          .then(() => {
            this.$emit('updated')
            this.coverForm.finishProcessing()
          })
          .catch(response => {
            this.coverForm.setErrors(response.body)
          })
      }
    },

    updateCover (e) {
      e.preventDefault()
      this.coverForm.startProcessing()

      var imageResize = new ImageResize({
        format: 'png',
        width: 200,
        outputType: 'blob'
      })

      const name = 'teamicon.png'
      imageResize.play(this.$refs.icon)
        .then(blob => {
          const file = new File([blob], name)
          this.handleFileUpload(file)
        })
    },

    handleFileUpload (file) {
      DirectUpload.store(file, {
        progress: progress => {
          this.uploadProgress = Math.round(progress * 100)
        }
      })
        .then(response => {
          this.$store.dispatch('teams/updateIcon', {
            team_id: this.team.id,
            uuid: response.uuid,
            key: response.key,
            name: file.name,
            content_type: file.type
          })
            .then(() => {
              this.$emit('updated')
              this.coverForm.finishProcessing()
            })
            .catch(response => {
              this.coverForm.setErrors(response.body)
            })
        })
        .catch(err => {
          console.warn('Failed upload of cover image', err)
          this.coverForm.stopProcessing()
          this.coverForm.setErrors({
            'form': ['Upload failed']
          })
        })
    }
  }

}
